<template>
  <configuration-set
    v-if="confData"
    :components="components"
    :speditor="speditor"
    :current-component="currentComponent"
    :loading="loading"
    @@changeComponent="changeComponent"
    @@save="save"
  >
    <template v-slot:content>
      <service-authorization
        v-show="currentComponent === 'ServiceAuthorization'"
        :is-new="isNew"
        @@input="update"
      />

      <additional-services
        v-show="currentComponent === 'AdditionalServices'"
        :is-new="isNew"
        @@input="update"
      />

      <content-and-comments
        v-show="currentComponent === 'ContentAndComments'"
        :is-new="isNew"
        @@input="update"
      />
    </template>
  </configuration-set>
</template>

<script>
import configurationApiService from '@/services/spedition/configuration-api';
import ConfigurationSet from '@/components/shared/ConfigurationSet.vue';
import { mapActions, mapGetters } from 'vuex';
import Pekaes from '@/interfaces/Speditors/Pekaes/Pekaes';
import ServiceAuthorization from './Forms/ServiceAuthorization.vue';
import AdditionalServices from './Forms/AdditionalServices.vue';
import ContentAndComments from './Forms/ContentAndComments.vue';

export default {
  name: 'Pekaes',
  components: {
    ConfigurationSet,
    ServiceAuthorization,
    AdditionalServices,
    ContentAndComments,
  },
  data: () => ({
    confData: null,
    isNew: null,
    speditor: 'Pekaes',
    currentComponent: 'ServiceAuthorization',
    components: [
      { code: 'ServiceAuthorization', name: 'courierSet.serviceAuthorizationName' },
      { code: 'AdditionalServices', name: 'courierSet.additionalServices' },
      { code: 'ContentAndComments', name: 'courierSet.contentAndComments' },
    ],
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet', 'getOrganization']),
  },
  created() {
    this.organizationId = this.getOrganization().id;
    this.isNew = !this.$route.params.confSetId;
  },
  mounted() {
    this.getConfiguration();
  },
  methods: {
    ...mapActions('spedition', ['setConfigSet', 'clearValues']),
    update(componentData) {
      switch (this.currentComponent) {
        case 'ServiceAuthorization':
          this.confData.configurationName = componentData.configurationName;
          this.confData.id = componentData.id;
          this.confData.password = componentData.password;
          this.confData.shipperId = componentData.shipperId;
          this.confData.url = componentData.url;
          break;
        case 'AdditionalServices':
          this.confData.additionalServices = componentData.additionalServices;
          this.confData.defaultValues.payer = componentData.payer;
          break;
        case 'ContentAndComments':
          this.confData.defaultValues.clientEntityState = componentData.clientEntityState;
          this.confData.defaultValues.remarks = componentData.remarks;
          this.confData.defaultValues.category = componentData.category;
          this.confData.defaultValues.payerType = componentData.payerType;
          this.confData.defaultValues.stackable = componentData.stackable;
          this.confData.defaultValues.bypass = componentData.bypass;
          this.confData.defaultValues.controlCode = componentData.controlCode;
          break;
        default:
          break;
      }
    },
    async save() {
      this.confData.tenantId = this.organizationId;
      this.confData.defaultValues.tenantId = this.organizationId;
      this.confData.defaultValues.payer.tenantId = this.organizationId;
      this.confData.defaultValues.pickup.tenantId = this.organizationId;
      this.confData.defaultValues.adrInfo.tenantId = this.organizationId;
      this.confData.defaultValues.delivery.tenantId = this.organizationId;

      if (this.isNew) {
        await configurationApiService.createConfigurationSet(
          this.speditor,
          this.confData,
          this.organizationId,
        );
      } else {
        await configurationApiService.updateConfigurationSet(
          this.speditor,
          this.confData,
          this.organizationId,
        );
      }
      this.clearValues();
      this.$router.go(-1);
    },
    changeComponent(component) {
      this.currentComponent = component;
    },
    getConfiguration() {
      if (!this.isNew) {
        this.confData = this.getConfigurationSet();
      } else {
        this.confData = Pekaes;
        this.confData.courier = this.speditor;
        this.setConfigSet({ response: this.confData });
      }
    },
  },
};
</script>
